<template>
    <v-container>
        <div class="d-flex flex-row">
            <v-text-field
                v-model="searchquery"
                placeholder="Neuigkeiten durchsuchen..."
                prepend-inner-icon="mdi-magnify"
                outlined
            />
            <div style="width:150px">
                <v-select 
                    v-model="entriesPerPage"
                    :items="entryOptions"
                    label="Einträge pro Seite"
                    class="ml-2"
                    outlined
                />
            </div>
        </div>
            <transition name="fade" mode="out-in">
                <div key="grp1" v-if="loading">
                    <v-skeleton-loader v-for="k in [1, 2, 3, 4, 5]"
                        :key="k"
                        class="mb-5"
                        elevation="6"
                        type="article, image, article@2"
                    />
                </div>
                <div key="grp2" v-else>
                    <transition-group name="fade" mode="out-in">
                        <v-card
                            v-for="entry in entriesToShow"
                            :key="entry.sys.id"
                            class="mb-5"
                            elevation="6"
                            outlined
                        >
                            <div class="ma-6">
                                <h2>{{entry.fields.title}}</h2>
                                <p style="font-size:90%;color:gray">
                                    {{convertDate(entry.sys.createdAt)}}
                                </p>
                                <rich-text-container :content="entry.fields.content"/>
                            </div>
                        </v-card>
                    </transition-group>
                </div>
            </transition>

        <v-pagination 
            v-model="selectedPage"
            :length="paginationLength"
        />
    </v-container>
</template>

<script>
import { createClient } from 'contentful'
import RichTextContainer from '@/components/RichTextContainer'
export default {
    name: "Blog",
    components:{ RichTextContainer },
    computed:{
        paginationLength: function(){
            return Math.ceil((this.searchquery == "" ? this.totalEntries : this.searchedTotalEntries) / this.entriesPerPage)
        }
    },
    watch:{
        searchquery: async function(){
            clearTimeout(this.debouncer)
            this.searchedEntries = {}
            if(this.searchquery != "")
            {
                this.debouncer = setTimeout(async () =>{
                    await this.showSearchPage(1)
                }, 500)
            }
            else{
                await this.showPage(1)
            }
            this.selectedPage = 1
        },
        entriesPerPage: async function(){
            this.entriesToShow = []
            this.blogEntries = {}
            this.searchedEntires = {}
            this.selectedPage = 1
            if(this.searchquery == "")
                this.showPage(1)
            else
                this.showSearchPage(1)
        },
        selectedPage: async function(value){
            if(this.searchquery == "")
                await this.showPage(value)
            else
                await this.showSearchPage(value)
        }
    },
    data(){
        return{
            blogClient: null,
            loading: false,
            entriesToShow: [],

            blogEntries: {},
            totalEntries: 0,

            entriesPerPage: 5,
            entryOptions:[3, 5, 10, 20, 50],
            selectedPage: 1,

            searchedEntries: {},
            searchedTotalEntries: 0,
            searchquery: "",

            debouncer: null
        }
    },
    methods:{
        convertDate(datestring){
            var date = new Date(datestring)
            var res = "vom " + date.getDate() + "." + date.getMonth() + "." + date.getFullYear()
            return res
        },
        async getEntries(skip, limit){
            var response = await this.blogClient.getEntries({ 'content_type': 'news', skip, limit })
            this.totalEntries = response.total
            return response.items
        },
        async searchEntries(query, skip, limit){
            var response = await this.blogClient.getEntries({ 'content_type': 'news', query, skip, limit })
            this.searchedTotalEntries = response.total
            return response.items
        },
        async showPage(page){
            if(!this.blogEntries[page]){
                this.loading = true
                try{
                    var items = await this.getEntries(this.entriesPerPage * (page - 1), this.entriesPerPage)
                    this.blogEntries[page] = items
                }
                catch(ex){
                    console.log(ex)
                }
                this.loading = false
            }
            this.entriesToShow = this.blogEntries[page]
        },
        async showSearchPage(page){
            if(!this.searchedEntries[page]){
                this.loading = true
                try{
                    var items = await this.searchEntries(this.searchquery, this.entriesPerPage * (page - 1), this.entriesPerPage)
                    this.searchedEntries[page] = items
                }
                catch(ex){
                    console.log(ex)
                }
                this.loading = false
            }
            this.entriesToShow = this.searchedEntries[page]
        }
    },
    async created(){
        this.blogClient = createClient({
            accessToken: '18rvALav6PO3mBiWlhrDaE8DV3qevcZWPjzljrBdjB0',
            space: '4r7oovmeughg'
        })
        this.showPage(1)
    }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>