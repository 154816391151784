<template>
    <v-container>
        <blog/>
    </v-container>
</template>

<script>
import Blog from '@/components/Blog'
export default {
    name: "Aktuelles",
    components:{ Blog }
}
</script>