<template>
    <div v-html="html"/>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
export default {
    name: "RichTextContainer",
    props: {
        content: Object
    },
    computed: {
        html: function() { 
            return documentToHtmlString(this.content, this.renderOptions) 
        }
    },
    data(){
        return{
            renderOptions: {
                renderNode: {
                    [BLOCKS.EMBEDDED_ASSET]: this.assetRenderer
                }
            }
        }
    },
    methods:{
        assetRenderer(node){
            return "<div style='line-height: 90%;display:flex;flex-direction:column'><div style='margin-left:auto;margin-right:auto'><img src='" + node.data.target.fields.file.url + 
                    "' style='max-width:100%;border-radius:.3em'/><br><span style='font-size:80%;color:gray'>" + 
                    node.data.target.fields.title + "</span></div></div>";
        }
    }
}
</script>